import styled, { css } from 'styled-components';
import theme from '../../theme/theme';

const Input = styled.input`
  width: 100%;
  border-radius: 4px;
  padding: 8px;
  border: 1px solid ${theme.palette.lightgray};
`;

export default Input;
