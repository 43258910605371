import { ReactNode } from 'react';
import styled from 'styled-components';
import TopNav from '../components/TopNav';
import theme from '../theme/theme';

export const navHeight = 84;

const Main = styled.main`
  margin-top: ${navHeight}px;
  background-color: ${theme.palette.beige};
  min-height: calc(100vh - ${navHeight}px);
`;

function MainLayout({ children }: { children: ReactNode }) {
  return (
    <>
      <TopNav height={navHeight} />
      <Main>
        {children}
      </Main>
    </>
  );
}

export default MainLayout;
