import { FieldValues, useForm } from 'react-hook-form';
import Button from '../../components/Button';
import Container from '../../components/Container';
import Spacer from '../../components/Spacer';
import Textarea from '../../components/Form/Textarea';
import {
  Body, H1, Label, Subtext,
} from '../../components/Typography';
import MainLayout from '../../layouts/MainLayout';
import Input from '../../components/Form/Input';

function SubmitPage() {
  const { register, handleSubmit } = useForm();
  const onSubmit = (data: FieldValues) => console.log(data);

  return (
    <MainLayout>
      <Container maxWidth="sm">
        <Spacer pt={24} pb={8}>
          <H1>Submit your own content</H1>
          <Body>Help the Brimmy team curate content quickly.</Body>
        </Spacer>
        <form onSubmit={handleSubmit(onSubmit)} method="POST">
          <Spacer pt={16} gap={24}>
            <Label>
              Content Body
              <Textarea {...register('content')} placeholder="Enter your content here." />
            </Label>
            <Label>
              Email
              <Subtext>
                If you provide an email, the Brimmy team will notify you once your content has been processed.
              </Subtext>
              <Input type="email" />
            </Label>
            <Body>Once the content has been submitted, the Brimmy team will review and add your content to the app whenever possible!</Body>
            <Spacer pt={16} gap={0}>
              <Button type="submit">
                Submit
              </Button>
            </Spacer>
          </Spacer>
        </form>
      </Container>
    </MainLayout>
  );
}

export default SubmitPage;
