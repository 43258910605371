import MainLayout from '../../layouts/MainLayout';
import Landing from './landing';

function HomePage() {
  return (
    <MainLayout>
      <Landing />
    </MainLayout>
  );
}

export default HomePage;
