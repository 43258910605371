import styled from 'styled-components';
import Container from '../../components/Container';
import Link from '../../components/Link';
import Spacer from '../../components/Spacer';
import { Body, H1 } from '../../components/Typography';

const Wrapper = styled.section`
`;

function Landing() {
  return (
    <Wrapper>
      <Container>
        <Spacer pt={32} px={16}>
          <H1 color="black">Website coming soon!</H1>
          <Body>
            We are currently only supporting mobile, but if you&apos;d like to see the web version sooner, email the developer team at seanpark1107@gmail.com or reach out via the
            {' '}
            <Link href="https://discord.gg/9SDysZmS" target="_blank">Discord server</Link>
            .
          </Body>
        </Spacer>
      </Container>
    </Wrapper>
  );
}

export default Landing;
