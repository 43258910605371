import styled, { css } from 'styled-components';
import { Link as RrdLink } from 'react-router-dom';
import theme from '../../theme/theme';

const LinkStyles = css`
color: inherit;
text-decoration: none;
position: relative;
font-weight: 600;

&:after {
  content: "";
  position: absolute;
  top: calc(100% + 4px);
  left: 0;
  width: 0;
  height: 2px;
  background-color: currentColor;
  transition: 0.2s;
}

&:hover:after {
  width: 100%;
}
`;

const Link = styled.a`${LinkStyles}`;
export const ReactLink = styled(RrdLink)`${LinkStyles}`;

export default Link;
