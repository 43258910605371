import { Link as RrdLink } from 'react-router-dom';
import styled from 'styled-components';
import Button from '../Button';
import Link, { ReactLink } from '../Link';
import Logo from '../Logo';

interface TopNavProps {
  height: number;
}

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: black;
  color: white;
`;

const Nav = styled.nav`
  padding: 16px 24px;
  height: ${(props: TopNavProps) => props.height}px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const NavLinks = styled.div`
  color: white;
  display: flex;
  gap: 16px;
  align-items: center;
`;

function TopNav({ height }: TopNavProps) {
  return (
    <Wrapper>
      <Nav height={height}>
        <div>
          <RrdLink to="/">
            <Logo />
          </RrdLink>
        </div>
        <NavLinks>
          <ReactLink to="/about">
            About
          </ReactLink>
          <ReactLink to="/submit-content">
            Submit Content
          </ReactLink>
          <RrdLink to="/download">
            <Button>
              Download
            </Button>
          </RrdLink>
        </NavLinks>
      </Nav>
    </Wrapper>
  );
}

export default TopNav;
