import styled from 'styled-components';
import theme from '../../theme/theme';

const Textarea = styled.textarea`
  min-height: 250px;
  width: 100%;
  border-radius: 4px;
  padding: 8px;
  border: 1px solid ${theme.palette.lightgray};
`;

export default Textarea;
