import styled, { css } from 'styled-components';
import theme, { Breakpoint } from '../../theme/theme';

interface ContainerProps {
  maxWidth?: keyof Breakpoint;
}

const Container = styled.div`
  margin: auto;
  max-width: ${(props: ContainerProps) => (props.maxWidth ? `${theme.breakpoints[props.maxWidth]}px` : 'none')};
  ${(props: ContainerProps) => Object.values(theme.breakpoints).filter((bp: number) => (props.maxWidth ? bp < theme.breakpoints[props.maxWidth] : true)).map((bp: number) => (
    css`@media only screen and (min-width: ${bp}px)  {
      max-width: ${bp}px;
    }`
  ))}
`;

export default Container;
