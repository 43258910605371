export interface Palette {
  [key: string]: string;
}

export interface Transition {
  [key: string]: string;
}

export interface Breakpoint {
  sm: number;
  md: number;
  lg: number;
  xl: number;
}

export interface Theme {
  palette: Palette;
  transitions: Transition;
  breakpoints: Breakpoint;
}

const theme: Theme = {
  palette: {
    primary: '#FF4B50',
    white: '#FFF',
    beige: '#ece8e1',
    lightgray: '#CCC',
    darkgray: '#777',
  },
  transitions: {
    bg: '0.3s ease-in-out',
  },
  breakpoints: {
    sm: 600,
    md: 900,
    lg: 1200,
    xl: 1536,
  },
};

export default theme;
