import { PropsWithChildren } from 'react';
import styled, { css } from 'styled-components';
import theme from '../../theme/theme';
import { ButtonProps } from './types';

const Label = styled.div`
  min-width: 8rem;
  line-height: 1.6;
  height: 100%;
  position: relative;

  &:before, &:after {
    content: "";
    display: block;
    width: calc(100% + 16px);
    height: calc(100% + 16px);
    background: ${(props: ButtonProps) => theme.palette[props.bgColor as string]};
    position: absolute;
  }

  &:before {
    bottom: -10px;
    left: -10px;
    z-index: -2;
  }

  &:after {
    left: -6px;
    top: -10px;
    z-index: -2;
  }
`;

const LabelText = styled.p`
  margin: 0;
  font-family: 'DINNextW05';
  padding: 0 4px;
  ${(props: ButtonProps) => props.bgColor === 'primary' && css`
    color: white;
  `}
`;

const CornerSquare = styled.div`
  width: 4px;
  height: 4px;
  position: absolute;
  top: calc(100% + 6px);
  left: calc(100% + 6px);
  overflow-x: hidden;
  background: black;
  z-index: -1;
  transition: ${theme.transitions.bg};
`;

const TransitionBackground = styled.div`
  overflow-x: hidden;
  position: absolute;
  left: -10px;
  top: -10px;
  width: calc(100% + 20px);
  height: calc(100% + 20px);

  &:after {
    content: "";
    position: absolute;
    top: -10px;
    right: calc(100% + 10px);
    background: black;
    width: calc(100% + 20px);
    height: calc(100% + 20px);
    z-index: -1;
    transition: ${theme.transitions.bg};
  }
`;

const Wrapper = styled.button`
  display: inline-block;
  position: relative;
  border: 0;
  text-align: center;
  cursor: pointer;
  padding: 16px;
  background: transparent;
  z-index: 2;

  &:before, &:after {
    content: "";
    display: block;
    position: absolute;
    height: calc(50% - 4px);
    border: 1px solid ${(props: ButtonProps) => theme.palette[props.borderColor as string]};
    width: 100%;
  }
  
  &:before {
    top: 0;
    left: 0;
    border-bottom: 0;
  }

  &:after {
    bottom: 0;
    left: 0;
    border-top: 0;
  }

  &:hover {
    ${CornerSquare} {
      background: white;
    }

    ${TransitionBackground}:after {
      transform: translateX(100%) skew(-20deg);
    }
  }
`;

function Button({ children, bgColor = 'primary', borderColor = 'lightgray' }: PropsWithChildren<ButtonProps>) {
  return (
    <Wrapper borderColor={borderColor}>
      <Label bgColor={bgColor}>
        <TransitionBackground />
        <CornerSquare />
        <LabelText bgColor={bgColor}>{children}</LabelText>
      </Label>
    </Wrapper>
  );
}

export default Button;
