import {
  BrowserRouter as Router,
  Routes,
  Route,
} from 'react-router-dom';
import './App.css';
import AboutPage from './pages/About';
import DownloadPage from './pages/Download';
import HomePage from './pages/Home';
import SubmitPage from './pages/Submit';
import ThemeProvider from './theme/ThemeProvider';

function App() {
  return (
    <ThemeProvider>
      <Router>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/submit-content" element={<SubmitPage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/download" element={<DownloadPage />} />
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;
