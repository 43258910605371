import { createContext, ReactNode, useContext } from 'react';
import theme, { Theme } from './theme';

const ThemeContext = createContext<Theme>(theme);

function ThemeProvider({ children }: { children: ReactNode}) {
  return <ThemeContext.Provider value={theme}>{children}</ThemeContext.Provider>;
}

export const useTheme = () => useContext(ThemeContext);

export default ThemeProvider;
