import styled from 'styled-components';

interface SpacerProps {
  pt?: number;
  pb?: number;
  px?: number;
  gap?: number;
}

const Spacer = styled.div`
  padding-top: ${(props: SpacerProps) => (props.pt ? props.pt : 0)}px;
  padding-bottom: ${(props: SpacerProps) => (props.pb ? props.pb : 0)}px;
  padding-left: ${(props: SpacerProps) => (props.px ? props.px : 0)}px;
  padding-right: ${(props: SpacerProps) => (props.px ? props.px : 0)}px;
  > * {
    margin-bottom: ${(props: SpacerProps) => (props.gap ? props.gap : 8)}px;
  }
`;

export default Spacer;
