import styled from 'styled-components';

const Wrapper = styled.div`
  font-family: 'Valorant';
  color: white;
  text-decoration: none;
`;

function Logo() {
  return (
    <Wrapper>
      Brimmy
    </Wrapper>
  );
}

export default Logo;
