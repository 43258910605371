import { H1 } from '../../components/Typography';
import MainLayout from '../../layouts/MainLayout';

function DownloadPage() {
  return (
    <MainLayout>
      <H1>Download</H1>
    </MainLayout>
  );
}

export default DownloadPage;
