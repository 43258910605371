import { H1 } from '../../components/Typography';
import MainLayout from '../../layouts/MainLayout';

function AboutPage() {
  return (
    <MainLayout>
      <H1>About</H1>
    </MainLayout>
  );
}

export default AboutPage;
