import styled from 'styled-components';
import theme, { Palette } from '../../theme/theme';

const getColorProp = (props: TypographyProps, defaultColor: string) => (props.color ? theme.palette[props.color] : defaultColor);
interface TypographyProps {
  color?: keyof Palette;
}

export const H1 = styled.h1`
  font-family: 'Tungsten';
  color: ${(props: TypographyProps) => getColorProp(props, theme.palette.primary)};
`;

export const Label = styled.label`
  display: block;
  font-weight: 600;
  > * {
    display: block;
    margin: 4px 0;
  }
`;

export const Body = styled.p`
  color: ${(props: TypographyProps) => getColorProp(props, theme.palette.black)};
  font-family: 'DINNextW05';
  margin: 0;
`;

export const Subtext = styled.small`
  color: ${(props: TypographyProps) => getColorProp(props, theme.palette.darkgray)};
  display: block;
  font-weight: 400;
`;
